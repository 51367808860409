<template>
  <div id="pageNotFound">
    <h2>{{ pageData.title }}</h2>
    <p>{{ pageData.plainText }}</p>
  </div>
</template>

<script>
import api from '@/api'
import viewMixin from '@/mixins/viewMixin'
import apiHelper from '@/api/helpers/helpers'

export default {
  name: 'FourOhFour',
  mixins: [viewMixin],
  data() {
    return {
      pageData: []
    }
  },
  mounted() {
    this.loadFourOhFourPage()
  },
  methods: {
    loadFourOhFourPage() {
      this.setFourOhFourData(apiHelper.readStoredResponse('404'))

      api.base.loadFourZeroFourPage().then(response => {
        this.setFourOhFourData(response)
      })
    },
    setFourOhFourData(response) {
      if (!response || !response.entry) return

      this.pageData = response.entry || {}
      this.toggleShowPageTrue()
    }
  }
}
</script>

<style lang="scss" scoped>
#pageNotFound {
  padding: 160px 0 240px;

  h2 {
    font-size: 55px;
  }

  p {
    font-size: 18px;
  }
}
</style>
