<template>
  <div id="genericPage">
    <iframe
      title="Brix Calculator"
      src="https://calculator.milnefruit.com"
      scrolling="no"
    ></iframe>
  </div>
</template>

<script>
import api from '@/api'
import viewMixin from '@/mixins/viewMixin'
import apiHelper from '@/api/helpers/helpers'

export default {
  name: 'GenericPage',
  components: {},
  mixins: [viewMixin],
  data() {
    return {
      heroData: []
    }
  },
  mounted() {
    this.loadGenericPage()
  },
  methods: {
    loadGenericPage() {
      this.setCalculatorPageData(apiHelper.readStoredResponse('calculator'))

      api.calculator.loadCalculatorPage().then(response => {
        if (response.entry === null) {
          this.$router.push({ path: '/404' })
        }

        this.setCalculatorPageData(response)
      })
    },
    setCalculatorPageData(/*response*/) {
      // if (!response || !response.entry) return
      this.toggleShowPageTrue()
      return
    }
  }
}
</script>

<style lang="scss" scoped>
#genericPage {
  iframe {
    @apply w-full overflow-hidden;
    height: 1000px;
  }
}
</style>
