<template>
  <div id="genericPage">
    <HeroContainer @loaded="toggleShowPageTrue" :hero-data="heroData" />
    <HighlightsTowerContainer :highlights-tower-data="highlightsTowerData" />
  </div>
</template>

<script>
import api from '@/api'
import HeroContainer from '@/components/Hero/HeroContainer'
import HighlightsTowerContainer from '@/components/HighlightsTower/HighlightsTowerContainer'
import viewMixin from '@/mixins/viewMixin'
import apiHelper from '@/api/helpers/helpers'

export default {
  name: 'GenericPage',
  components: { HeroContainer, HighlightsTowerContainer },
  mixins: [viewMixin],
  data() {
    return {
      heroData: [],
      highlightsTowerData: []
    }
  },
  mounted() {
    this.loadGenericPage()
  },
  methods: {
    loadGenericPage() {
      const slug = this.$route.params.genericPageSlug

      this.setGenericPageData(
        apiHelper.readStoredResponse(`genericPage.${slug}`)
      )

      api.genericPage.loadGenericPage(slug).then(response => {
        if (response.entry === null) {
          this.$router.push({ path: '/404' })
        }

        this.setGenericPageData(response)
      })
    },
    setGenericPageData(response) {
      if (!response || !response.entry) return

      this.heroData = response.entry.hero || []
      this.highlightsTowerData = response.entry.highlightsTower || []
    }
  }
}
</script>
